/* eslint-disable no-console */
// tell the mini-cart in the header to update its quantity
// Add a payload with source details so internal event handler
// can ignore this if necessary
export const updateMiniCartQuantity = () => {
  try {
    if (typeof window !== 'undefined' && typeof jQuery !== 'undefined') {
      /* eslint-disable no-undef */
      window.top.$(window.top.document).trigger({ type: 'cartUpdated' }, { source: 'cartMutation' });
    } else if (typeof window !== 'undefined') {
      window.top.LIFE_CYCLE_EVENT_BUS.trigger('add-to-cart.success', { source: 'cartMutation' });
    }
  } catch (err) { /* console.log(' Error on updateMiniCartQuantity:', err); */ }
};

// optimalFulfillment supported by updateCart, deleteItem, getCart/viewCart
export const OPTIMAL_OVERRIDE = 'optimal_override';
export const OPTIMAL_FULFILLMENT = 'optimal_fulfillment';

let optimalObjOnLoad;

export const optimalOverride = (mutationName) => {
  try {
    if (mutationName === 'getCart' && optimalObjOnLoad) {
      return optimalObjOnLoad;
    }
    // optimal fulfillment logic override
    if (typeof window !== 'undefined' && localStorage.getItem(OPTIMAL_OVERRIDE)) {
      // only getCart is optimal override
      // eslint-disable-next-line
      if (mutationName === 'getCart') {
        optimalObjOnLoad = { optimalFulfillment: false };
        return optimalObjOnLoad;
      }
      localStorage.removeItem(OPTIMAL_OVERRIDE);
    }
    return { optimalFulfillment: true };
  } catch (err) { /* console.log(' Error on setting optimalHeaders:', err); */ }
  return { optimalFulfillment: true };
};

export const getExchangeCartId = () => {
  // get exchange cart id from LS or THD_STOREFRONT cookie
  const thdStorefrontCookie = 'THD_STOREFRONT';
  if (typeof window !== 'undefined') {
    if (localStorage.getItem('exchangeCartId')) {
      return localStorage.getItem('exchangeCartId');
    }

    const cookie = window?.cookieUtils?.readBrowserCookie(thdStorefrontCookie);
    if (cookie) {
      try {
        const objTokenCookie = cookie.split('.');
        if (objTokenCookie.length > 1) {
          let base64 = objTokenCookie[1].replace(/-/g, '+').replace(/_/g, '/');
          while (base64.length % 4) {
            base64 += '=';
          }
          const getThdCartId = JSON.parse(atob(base64));
          return getThdCartId?.thdCartId || null;
        }
      } catch (err) {
        console.error('Error decoding JWT:', err.message);
      }

    }

  }
  return null;
};

export const getUrlParam = (param) => {
  const searchParams = typeof window !== 'undefined' ? window?.top.location?.search : '';
  const urlParams = new URLSearchParams(searchParams);
  return urlParams.get(param);
};

export const getTmxId = () => {
  if (typeof window === 'undefined') return null;

  const nativeTMXID = window.__EXPERIENCE_CONTEXT__?.isConsumerApp
    ? getUrlParam('guid')
    : window.THD_ACCOUNT_LIB?.TMX?.getTMXsessionId();

  return nativeTMXID;
};

export const getTrueClientIp = () => {
  if (typeof window === 'undefined') {
    return undefined;
  }

  return window?.trueClientIp;
};

export const getIsBrandPricingPolicyCompliant = () => {
  if (typeof window === 'undefined') { return false; }
  return window?.top?.THDCustomer?.default?.isCustomerIdentified || false;
};